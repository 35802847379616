import React from "react";
import "./onlineCourses.scss";
import { Container, Row, Col } from "react-bootstrap";

export const CourseIntro = ({ children, type }) => {
  return (
    <div id="description">
      <Container fluid="lg">
        {type === "growing" ? (
          <>
            <div className="mb-md-5 my-4 px-2 px-lg-0">
              <Row className="justify-content-center align-items-center pb-md-5 py-4">
                <Col xl={8} lg={11} xs={12}>
                  {children}
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            <div className="my-md-5 my-4 px-2 px-lg-0">
              <Row className="justify-content-center align-items-center py-md-5 py-4">
                <Col xl={8} lg={11} xs={12}>
                  {children}
                </Col>
              </Row>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export const CourseIntroImage = ({ children }) => {
  return (
    <Container fluid="lg">
      <div>
        <Row className="justify-content-center align-items-center">
          <Col xs={12}>{children}</Col>
        </Row>
      </div>
    </Container>
  );
};

export const CourseIntroQuote = ({ children }) => {
  return (
    <div id="introQuote">
      <Container fluid="xl">
        <Row className="justify-content-center">
          <Col lg={12}>
            <Row className="white-bg pt-xl-5 pt-4">
              <Col className="p-4 text-center">
                <p className="highlight-text">
                  <span>{children}</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
