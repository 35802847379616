import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

import "./styles/subNavigation.scss";

const SubNavigation = ({ chevron, subNav, type }) => {
  if (chevron === "true") {
    return (
      <div id="subNav">
        <Container fluid="lg">
          <Row>
            <Nav>
              {subNav.map((node, index) => (
                <Nav.Item key={index}>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    color="#fec311"
                    size="sm"
                  />
                  <Nav.Link href={`#${node.url}`}>{node.text}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Row>
        </Container>
      </div>
    );
  }
  if (type === "new") {
    return (
      <div id="subNav" className="white">
        <Container fluid="lg">
          <Row>
            <Nav className="text-center">
              {subNav.map((node, index) => (
                <Nav.Item key={index}>
                  <Nav.Link href={`#${node.url}`}>{node.text}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div id="subNav">
        <Container fluid="lg">
          <Row>
            <Nav>
              {subNav.map((node, index) => (
                <Nav.Item key={index}>
                  <Nav.Link href={`#${node.url}`}>{node.text}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Row>
        </Container>
      </div>
    );
  }
};

export default SubNavigation;
