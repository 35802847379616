import React from "react";
import "./onlineCourses.scss";
import { Container, Row, Col, Card } from "react-bootstrap";

function CourseModuleSection({ children }) {
  return (
    <div className="medium-light-gray-bg" id="course_modules">
      <Container fluid="lg">
        <div className="py-xl-5 py-4 px-2 px-lg-0">
          <Row className="justify-content-center align-items-center py-xl-5 py-4">
            <Col xs={12}>
              <h2>Course Modules</h2>
              <Row className="mb-4 mb-xl-5">{children}</Row>
              <p className="text-center">
                <small>
                  *Course modules are illustrative. Faculty build dynamic course
                  content and class structure aligned with and beyond the listed
                  modules.
                </small>
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

function CourseModule({ children, courseModuleTitle, courseModuleNumber }) {
  return (
    <Col xs={12} md={6} lg={4} xl={3} className="d-flex align-items-stretch">
      <Card>
        <Card.Header>
          <Card.Subtitle>Module {courseModuleNumber}</Card.Subtitle>
          <Card.Title>{courseModuleTitle}</Card.Title>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Col>
  );
}

export { CourseModuleSection, CourseModule };
